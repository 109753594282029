import { clone } from "lodash";
import { DialogProgrammatic as Dialog } from "buefy";
// pagination list view mixin

export default {
  beforeRouteLeave(to, from, next) {
    if (this.needFilter) {
      this.filterMap = clone(this.filterPrevMap);
      next();
    } else if (this.needConfirmation()) {
      this.showConfirmationDlg(
        () => {
          this.saveObjects(next);
        },
        () => next(false)
      );
    } else {
      next();
    }
  },
  methods: {
    applyFilter() {
      this.listMdl.applyFilter();
    },
    needConfirmation() {
      return false;
    },
    needFilter() {
      let keys = Object.keys(this.filterPrevMap);
      return keys.reduce((res, key) => res || this.filterPrevMap[key] !== this.filterMap[key], false);
    },
    updatePage(data) {
      const dataSize = Object.keys(data).length;
      const total = this.pagination.total - dataSize;
      if (this.pagination.prevPage > (Math.ceil(total / this.pagination.limit))) this.pagination.prevPage--;
      if (this.pagination.prevPage < 1) this.pagination.prevPage = 1;
      this.pagination.page = this.pagination.prevPage;
      this.listMdl.load();
    },
    pageChange(page) {
      if (this.needConfirmation()) {
        this.showConfirmationDlg(
          () => {
            this.saveObjects((data) => {
              this.updatePage(data);
            });
          },
          () => (this.pagination.page = this.pagination.prevPage)
        );
      } else if (this.needFilter()) {
        Dialog.confirm({
          title: "Filter Data",
          message:
            "Anda telah mengubah data filter. Filter data terlebih dahulu?",
          confirmText: "Filter",
          cancelText: "Tidak",
          type: "is-danger",
          hasIcon: true,
          onConfirm: () => this.applyFilter(),
          onCancel: () => {
            this.listMdl.resetFilter();
            this.listMdl.pageChange(page, this.scrollToTop);
          }
        });
      } else {
        this.listMdl.pageChange(page, this.scrollToTop);
      }
    },
    fetchData() {
      this.listMdl.load();
    },
    saveObjects(next) {
      if (!this.needConfirmation()) return;
      let onSaved = next ? next : this.updatePage;
      this.listMdl.update(onSaved);
    },
    showConfirmationDlg(onConfirm, onCancel) {
      let dlg_params = {
        title: "Simpan Perubahan",
        message:
          "Yakin akan pindah halaman? Terdapat data yang belum tersimpan!",
        confirmText: "Simpan perubahan",
        type: "is-danger",
        hasIcon: true
      };
      if (onConfirm) dlg_params.onConfirm = onConfirm;
      if (onCancel) dlg_params.onCancel = onCancel;
      Dialog.confirm(dlg_params);
    },
    scrollToTop() {
      document.getElementById("app").scrollIntoView();
    }
  }
}
